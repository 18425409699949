import React from "react"

export default {
  fill: "none",
  viewBox: "0 0 73 73",
  path: (
    <>
      <path
        d="M16.5942 40.0016V67.911C16.5942 69.6914 17.5008 71.3122 19.0188 72.2431C20.537 73.1754 22.3915 73.2509 23.9796 72.4442L29.0072 69.8912L36.1345 66.2732C36.2115 66.2333 36.2956 66.2077 36.3797 66.1962C36.5437 66.1706 36.7146 66.1977 36.8658 66.2732L44.1058 69.9495L49.0208 72.444C49.7521 72.8146 50.539 72.9999 51.3245 72.9999C52.2454 72.9999 53.1619 72.7461 53.9816 72.243C55.4997 71.3121 56.4062 69.6914 56.4062 67.9109V40.0016C56.4062 38.8214 55.4483 37.8634 54.268 37.8634H29.0072C23.2198 37.8634 18.7323 37.8634 18.7323 37.8634C17.5507 37.8634 16.5942 38.8214 16.5942 40.0016Z"
        fill="#FF873C"
      />
      <path
        d="M16.5938 40.0016V67.911C16.5938 69.6914 17.5005 71.3122 19.0185 72.2431C20.5367 73.1754 22.3912 73.2509 23.9792 72.4442L29.0068 69.8912L36.1342 66.2732C36.2112 66.2333 36.2953 66.2077 36.3794 66.1963V37.8634H29.0068C23.2194 37.8634 18.7319 37.8634 18.7319 37.8634C17.5504 37.8634 16.5938 38.8214 16.5938 40.0016Z"
        fill="#FA732D"
      />
      <path
        d="M29.0069 37.8634V69.891L36.1342 66.2731C36.2112 66.2332 36.2953 66.2075 36.3794 66.1961C36.5434 66.1704 36.7144 66.1975 36.8655 66.2731L44.1055 69.9493V37.8634H29.0069Z"
        fill="#FF2121"
      />
      <path
        d="M29.0068 37.8634V69.891L36.1342 66.2731C36.2112 66.2332 36.2953 66.2075 36.3794 66.1961V37.8634H29.0068Z"
        fill="#CF0404"
      />
      <path
        d="M11.4455 25.0555C11.4455 38.8313 22.6184 50.0455 36.3799 50.1111H36.4996C50.3153 50.1111 61.5552 38.8712 61.5552 25.0555C61.5552 11.2399 50.3153 0 36.4996 0H36.3799C22.6184 0.0655859 11.4455 11.2798 11.4455 25.0555Z"
        fill="#717171"
      />
      <path
        d="M11.4445 25.0555C11.4445 38.8313 22.6174 50.0455 36.3789 50.1111V0C22.6174 0.0655859 11.4445 11.2798 11.4445 25.0555Z"
        fill="#979797"
      />
      <path
        d="M19.415 25.0555C19.415 34.4352 27.0143 42.0742 36.3795 42.1384C36.4194 42.1398 36.4593 42.1398 36.4993 42.1398C45.9202 42.1398 53.585 34.4751 53.585 25.0555C53.585 15.6346 45.9202 7.96985 36.4993 7.96985C36.4593 7.96985 36.4194 7.96985 36.3795 7.97127C27.0141 8.03543 19.415 15.6745 19.415 25.0555Z"
        fill="#979797"
      />
      <path
        d="M19.4149 25.0556C19.4149 34.4352 27.0141 42.0743 36.3794 42.1384V7.97131C27.014 8.03547 19.4149 15.6745 19.4149 25.0556Z"
        fill="#BABABA"
      />
      <path
        d="M37.4594 20.8605V29.2494C37.4594 30.4297 38.4173 31.3876 39.5976 31.3876C40.7779 31.3876 41.7358 30.4297 41.7358 29.2494V20.8605C41.7358 19.6803 40.7779 18.7223 39.5976 18.7223C38.4172 18.7223 37.4594 19.6801 37.4594 20.8605Z"
        fill="#717171"
      />
      <path
        d="M31.0228 20.8611V29.25C31.0228 30.4303 31.9808 31.3882 33.1611 31.3882C34.3413 31.3882 35.2993 30.4303 35.2993 29.25V20.8611C35.2993 19.6809 34.3413 18.7229 33.1611 18.7229C31.9808 18.7229 31.0228 19.6807 31.0228 20.8611Z"
        fill="#979797"
      />
    </>
  ),
}
