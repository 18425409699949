import React from "react"

export default {
  fill: "none",
  viewBox: "0 0 142 142",
  path: (
    <>
      <path
        d="M82.0938 104.281H73.2188V97.625C73.2154 94.6838 72.0456 91.8639 69.9658 89.7842C67.8861 87.7044 65.0662 86.5346 62.125 86.5313C61.6121 86.5312 61.115 86.7089 60.7183 87.0341C60.3216 87.3593 60.0498 87.8119 59.9492 88.3148L57.8167 98.9782L54.2814 104.281H48.8125V102.062C48.8125 101.474 48.5787 100.91 48.1626 100.494C47.7465 100.078 47.1822 99.8437 46.5938 99.8437H19.9688V104.281H44.375V130.906H19.9688V135.344H46.5938C47.1822 135.344 47.7465 135.11 48.1626 134.694C48.5787 134.278 48.8125 133.713 48.8125 133.125V130.906H52.355C56.9585 135.2 63.0243 137.58 69.3196 137.562H82.0938C84.4467 137.56 86.7026 136.624 88.3664 134.96C90.0302 133.296 90.9661 131.04 90.9688 128.687V113.156C90.9661 110.803 90.0302 108.547 88.3664 106.884C86.7026 105.22 84.4467 104.284 82.0938 104.281ZM86.5312 128.687C86.5299 129.864 86.0619 130.992 85.23 131.824C84.3981 132.656 83.2702 133.124 82.0938 133.125H69.3196C66.6255 133.132 63.9566 132.605 61.4676 131.574C58.9785 130.543 56.7187 129.029 54.8189 127.119C54.6129 126.913 54.3683 126.749 54.0991 126.638C53.8299 126.526 53.5414 126.469 53.25 126.469H48.8125V108.719H55.4688C55.834 108.719 56.1936 108.629 56.5156 108.456C56.8376 108.284 57.1121 108.035 57.3148 107.731L61.7523 101.075C61.9131 100.833 62.0251 100.563 62.082 100.279L63.8961 91.2078C65.2993 91.5974 66.5365 92.4356 67.4186 93.5943C68.3007 94.7531 68.7793 96.1687 68.7812 97.625V104.281H64.3438V108.719H82.0938C83.2702 108.72 84.3981 109.188 85.23 110.02C86.0619 110.852 86.5299 111.98 86.5312 113.156V128.687Z"
        fill="#1F3C88"
      />
      <path d="M35.5 122.031H39.9375V126.469H35.5V122.031Z" fill="#1F3C88" />
      <path
        d="M122.031 4.4375H28.8438C28.2553 4.4375 27.691 4.67126 27.2749 5.08736C26.8588 5.50345 26.625 6.0678 26.625 6.65625V95.4062H31.0625V8.875H119.812V124.25H95.4062V128.688H122.031C122.62 128.688 123.184 128.454 123.6 128.038C124.016 127.622 124.25 127.057 124.25 126.469V6.65625C124.25 6.0678 124.016 5.50345 123.6 5.08736C123.184 4.67126 122.62 4.4375 122.031 4.4375Z"
        fill="#1F3C88"
      />
      <path
        d="M64.3438 35.5C64.3432 36.6379 64.5184 37.7692 64.8633 38.8536L57.8786 54.5676C57.6471 55.0893 57.6255 55.6801 57.8183 56.2173C58.0111 56.7545 58.4035 57.1967 58.914 57.452L67.7889 61.8895C68.0671 62.0286 68.3714 62.1077 68.682 62.1216C68.9927 62.1355 69.3028 62.084 69.5923 61.9704C69.8817 61.8567 70.1441 61.6835 70.3623 61.4619C70.5805 61.2403 70.7497 60.9753 70.8589 60.6842L75.4375 48.4741L80.0162 60.6853C80.1254 60.9765 80.2946 61.2414 80.5128 61.463C80.731 61.6846 80.9933 61.8578 81.2828 61.9715C81.5723 62.0851 81.8824 62.1367 82.193 62.1227C82.5037 62.1088 82.808 62.0297 83.0861 61.8906L91.9611 57.4531C92.4715 57.1978 92.8639 56.7556 93.0568 56.2184C93.2496 55.6812 93.228 55.0904 92.9964 54.5687L86.0118 38.8547C86.4958 37.3293 86.644 35.7172 86.4463 34.129C86.2485 32.5409 85.7095 31.0144 84.8662 29.6542C84.0229 28.294 82.8953 27.1323 81.5608 26.249C80.2263 25.3656 78.7164 24.7815 77.1349 24.5366C75.5534 24.2918 73.9375 24.392 72.3984 24.8304C70.8592 25.2688 69.4331 26.0351 68.218 27.0766C67.0029 28.1181 66.0275 29.4102 65.3587 30.8642C64.69 32.3181 64.3438 33.8996 64.3438 35.5ZM67.569 56.8194L62.7948 54.4326L67.6866 43.4265C68.8157 44.5328 70.1678 45.3857 71.6526 45.9281L67.569 56.8194ZM83.306 56.8194L79.2224 45.9281C80.7073 45.3857 82.0594 44.5328 83.1885 43.4265L88.0802 54.4326L83.306 56.8194ZM75.4375 28.8437C76.754 28.8437 78.0409 29.2341 79.1355 29.9655C80.2302 30.6969 81.0833 31.7365 81.5871 32.9527C82.0909 34.169 82.2227 35.5074 81.9659 36.7985C81.709 38.0897 81.0751 39.2758 80.1442 40.2067C79.2133 41.1375 78.0273 41.7715 76.7361 42.0283C75.4449 42.2852 74.1066 42.1533 72.8903 41.6495C71.674 41.1458 70.6345 40.2926 69.9031 39.198C69.1717 38.1034 68.7813 36.8165 68.7813 35.5C68.7833 33.7353 69.4853 32.0434 70.7331 30.7956C71.981 29.5477 73.6728 28.8458 75.4375 28.8437Z"
        fill="#1F3C88"
      />
      <path
        d="M73.2188 15.5312H77.6562V19.9688H73.2188V15.5312Z"
        fill="#1F3C88"
      />
      <path
        d="M59.7397 22.9529L62.8773 19.8154L66.0148 22.9529L62.8773 26.0904L59.7397 22.9529Z"
        fill="#1F3C88"
      />
      <path
        d="M55.4688 33.2812H59.9062V37.7188H55.4688V33.2812Z"
        fill="#1F3C88"
      />
      <path
        d="M90.9688 33.2812H95.4062V37.7188H90.9688V33.2812Z"
        fill="#1F3C88"
      />
      <path
        d="M84.8392 22.9561L87.9767 19.8185L91.1142 22.9561L87.9767 26.0936L84.8392 22.9561Z"
        fill="#1F3C88"
      />
      <path
        d="M73.2188 33.2812H77.6562V37.7188H73.2188V33.2812Z"
        fill="#1F3C88"
      />
      <path d="M44.375 66.5625H48.8125V71H44.375V66.5625Z" fill="#1F3C88" />
      <path d="M53.25 66.5625H110.938V71H53.25V66.5625Z" fill="#1F3C88" />
      <path
        d="M39.9375 75.4375H110.938V79.875H39.9375V75.4375Z"
        fill="#1F3C88"
      />
      <path
        d="M73.2188 84.3125H110.938V88.75H73.2188V84.3125Z"
        fill="#1F3C88"
      />
      <path
        d="M39.9375 84.3125H55.4688V88.75H39.9375V84.3125Z"
        fill="#1F3C88"
      />
      <path d="M106.5 110.938H110.938V115.375H106.5V110.938Z" fill="#1F3C88" />
      <path
        d="M97.625 110.938H102.062V115.375H97.625V110.938Z"
        fill="#1F3C88"
      />
    </>
  ),
}
