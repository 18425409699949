import React from "react"

export default {
  fill: "none",
  viewBox: "0 0 54 54",
  path: (
    <>
      <path
        d="M27 24.3175C30.5572 24.3175 33.9686 25.7306 36.4839 28.2458C38.9992 30.7611 40.4122 34.1726 40.4122 37.7297V53.8243H13.5879V37.7297C13.5879 34.1726 15.0009 30.7611 17.5162 28.2458C20.0315 25.7306 23.4429 24.3175 27 24.3175ZM8.99552 32.3809C8.56863 33.8208 8.31646 35.3068 8.24444 36.8069L8.22298 37.7297V53.8243H0.175659V41.7534C0.175131 39.4399 1.02875 37.2078 2.57278 35.4851C4.11681 33.7623 6.24253 32.6703 8.54219 32.4185L8.9982 32.3809H8.99552ZM45.0046 32.3809C47.3917 32.5264 49.6334 33.5772 51.2724 35.3188C52.9113 37.0605 53.8241 39.3618 53.8244 41.7534V53.8243H45.7771V37.7297C45.7771 35.8708 45.5089 34.0762 45.0046 32.3809ZM9.5642 16.2702C11.3428 16.2702 13.0485 16.9767 14.3061 18.2343C15.5638 19.492 16.2703 21.1977 16.2703 22.9763C16.2703 24.7548 15.5638 26.4606 14.3061 27.7182C13.0485 28.9758 11.3428 29.6824 9.5642 29.6824C7.78563 29.6824 6.07991 28.9758 4.82227 27.7182C3.56463 26.4606 2.8581 24.7548 2.8581 22.9763C2.8581 21.1977 3.56463 19.492 4.82227 18.2343C6.07991 16.9767 7.78563 16.2702 9.5642 16.2702ZM44.4359 16.2702C46.2145 16.2702 47.9202 16.9767 49.1778 18.2343C50.4355 19.492 51.142 21.1977 51.142 22.9763C51.142 24.7548 50.4355 26.4606 49.1778 27.7182C47.9202 28.9758 46.2145 29.6824 44.4359 29.6824C42.6573 29.6824 40.9516 28.9758 39.694 27.7182C38.4363 26.4606 37.7298 24.7548 37.7298 22.9763C37.7298 21.1977 38.4363 19.492 39.694 18.2343C40.9516 16.9767 42.6573 16.2702 44.4359 16.2702ZM27 0.175537C29.8458 0.175537 32.5749 1.30599 34.5871 3.31821C36.5994 5.33043 37.7298 8.05959 37.7298 10.9053C37.7298 13.751 36.5994 16.4802 34.5871 18.4924C32.5749 20.5046 29.8458 21.6351 27 21.6351C24.1543 21.6351 21.4252 20.5046 19.413 18.4924C17.4007 16.4802 16.2703 13.751 16.2703 10.9053C16.2703 8.05959 17.4007 5.33043 19.413 3.31821C21.4252 1.30599 24.1543 0.175537 27 0.175537Z"
        fill="#1F3C88"
      />
    </>
  ),
}
