import React from "react"

export default {
  fill: "none",
  viewBox: "0 0 54 54",
  path: (
    <>
      <path
        d="M51.03 3.24H44.82V0.54C44.82 0.243 44.577 0 44.28 0H9.72C9.423 0 9.18 0.243 9.18 0.54V3.24H2.97C2.18231 3.24 1.42688 3.55291 0.869893 4.10989C0.31291 4.66688 0 5.42231 0 6.21V16.2C0 21.7148 4.05 26.298 9.3285 27.135C10.368 34.9785 16.6725 41.1615 24.57 42.0188V49.1198H11.34C10.1453 49.1198 9.18 50.085 9.18 51.2798V53.46C9.18 53.757 9.423 54 9.72 54H44.28C44.577 54 44.82 53.757 44.82 53.46V51.2798C44.82 50.085 43.8548 49.1198 42.66 49.1198H29.43V42.0188C37.3275 41.1615 43.632 34.9785 44.6715 27.135C49.95 26.298 54 21.7148 54 16.2V6.21C54 5.42231 53.6871 4.66688 53.1301 4.10989C52.5731 3.55291 51.8177 3.24 51.03 3.24ZM9.18 22.113C6.67575 21.3098 4.86 18.9608 4.86 16.2V8.1H9.18V22.113ZM49.14 16.2C49.14 18.9675 47.3243 21.3165 44.82 22.113V8.1H49.14V16.2Z"
        fill="#1F3C88"
      />
    </>
  ),
}
