import React from "react"

export default {
  fill: "none",
  viewBox: "0 0 49 42",
  path: (
    <>
      <path
        d="M22.1956 26.9285H13.9548C13.4672 26.9285 13.0723 27.3234 13.0723 27.8111V40.6059C13.0723 41.0936 13.4672 41.4885 13.9548 41.4885H22.1956C22.6833 41.4885 23.0776 41.0936 23.0776 40.6059V27.8105C23.0776 27.3228 22.6827 26.9285 22.1956 26.9285Z"
        fill="#1F3C88"
      />
      <path
        d="M35.0447 0.512207H26.804C26.3163 0.512207 25.9214 0.907124 25.9214 1.39479V40.6053C25.9214 41.093 26.3163 41.4879 26.804 41.4879H35.0447C35.5324 41.4879 35.9273 41.093 35.9273 40.6053V1.39479C35.9273 0.907124 35.5324 0.512207 35.0447 0.512207Z"
        fill="#1F3C88"
      />
      <path
        d="M47.9598 12.4244H39.719C39.2313 12.4244 38.8364 12.8194 38.8364 13.307V40.6053C38.8364 41.0929 39.2313 41.4879 39.719 41.4879H47.9592C48.4468 41.4879 48.8418 41.0929 48.8418 40.6053V13.307C48.8418 12.8199 48.4468 12.4244 47.9598 12.4244Z"
        fill="#1F3C88"
      />
      <path
        d="M9.28105 12.4244H1.0403C0.552631 12.4244 0.157715 12.8194 0.157715 13.307V40.6053C0.157715 41.0929 0.552631 41.4879 1.0403 41.4879H9.28105C9.76871 41.4879 10.1636 41.0929 10.1636 40.6053V13.307C10.1636 12.8199 9.76871 12.4244 9.28105 12.4244Z"
        fill="#1F3C88"
      />
    </>
  ),
}
