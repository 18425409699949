import React from "react"

export default {
  fill: "none",
  viewBox: "0 0 73 73",
  path: (
    <>
      <g clip-path="url(#clip0)">
        <path
          d="M22.9846 36.9863C17.3036 36.9863 12.5029 34.7792 9.10138 30.6038C6.01014 26.8091 4.30762 21.5327 4.30762 15.7468V9.49257C4.30762 7.78562 5.69633 6.39691 7.40327 6.39691H19.2939C20.4758 6.39691 21.4341 7.35518 21.4341 8.53715C21.4341 9.71912 20.4758 10.6774 19.2939 10.6774H8.5881V15.7469C8.5881 20.5511 9.94901 24.8673 12.42 27.9007C14.9812 31.0447 18.6344 32.7064 22.9848 32.7064C24.1668 32.7064 25.125 33.6647 25.125 34.8467C25.125 36.0286 24.1668 36.9863 22.9846 36.9863Z"
          fill="#CFF9FF"
        />
        <path
          d="M50.1598 36.9864C48.9778 36.9864 48.0195 36.0281 48.0195 34.8461C48.0195 33.6641 48.9778 32.7059 50.1598 32.7059C54.4957 32.7059 58.1211 31.0477 60.6438 27.9107C63.0738 24.889 64.4122 20.5691 64.4122 15.7465V10.6773H53.7622C52.5802 10.6773 51.6219 9.719 51.6219 8.53703C51.6219 7.35506 52.5802 6.39679 53.7622 6.39679H65.5967C67.3037 6.39679 68.6924 7.7855 68.6924 9.49245V15.7466C68.6924 21.5414 67.0186 26.8141 63.9794 30.5933C60.6159 34.7757 55.8372 36.9864 50.1598 36.9864ZM65.5967 10.6773H65.5981H65.5967Z"
          fill="#80DBFF"
        />
        <path
          d="M55.8769 5.19498V24.3367C55.8769 29.7742 53.5698 34.9948 49.5463 38.6574C49.0655 39.0939 48.6202 39.4763 48.2264 39.8131C46.3973 41.3783 45.9065 41.7992 45.9094 43.8937L45.9308 60.5057C45.9322 61.6886 44.9748 62.6474 43.7934 62.6488H30.0663C30.0648 62.6488 30.0634 62.6488 30.0634 62.6488C28.8806 62.6474 27.9246 61.6886 27.926 60.5057L27.946 44.4231C27.9488 42.1688 27.3824 41.7122 25.2751 40.0128C24.9012 39.7119 24.4775 39.3708 24.0309 38.9941C19.6606 35.3031 17.1538 29.9083 17.1538 24.1941V5.19498C17.154 2.32987 19.484 0 22.3489 0H50.6835C53.547 0 55.8769 2.32987 55.8769 5.19498Z"
          fill="#80DBFF"
        />
        <path
          d="M55.8771 5.19498V24.3367C55.8771 29.7742 53.57 34.9948 49.5465 38.6574C49.0657 39.0939 48.6204 39.4763 48.2266 39.8131C46.3975 41.3783 45.9067 41.7992 45.9096 43.8937L45.931 60.5057C45.9324 61.6886 44.975 62.6474 43.7936 62.6488H37.229V0H50.6835C53.5472 0 55.8771 2.32987 55.8771 5.19498Z"
          fill="#69A8FF"
        />
        <path
          d="M54.3189 62.362V73H19.8691V62.362C19.8691 60.1604 21.6612 58.3684 23.8628 58.3684H50.3268C52.5284 58.3684 54.3189 60.1604 54.3189 62.362Z"
          fill="#9C9C9C"
        />
        <path
          d="M54.319 62.362V73H37.229V58.3684H50.3269C52.5285 58.3684 54.319 60.1604 54.319 62.362Z"
          fill="#848484"
        />
        <path
          d="M58.0928 70.8598C58.0928 72.0426 57.1339 73 55.9525 73H18.2369C17.0555 73 16.0967 72.0426 16.0967 70.8598C16.0967 69.6784 17.0555 68.7195 18.2369 68.7195H55.9525C57.1339 68.7197 58.0928 69.6785 58.0928 70.8598Z"
          fill="#DCE1EB"
        />
        <path
          d="M35.9445 12.5286V19.8166C35.9445 20.998 34.9871 21.9569 33.8043 21.9569C32.6229 21.9569 31.6641 20.998 31.6641 19.8166V12.5286C31.6641 11.3472 32.6229 10.3884 33.8043 10.3884C34.9871 10.3884 35.9445 11.3472 35.9445 12.5286Z"
          fill="#CFF9FF"
        />
        <path
          d="M43.5662 27.1631C43.5662 28.3445 42.6088 29.3033 41.426 29.3033H33.0309C31.8495 29.3033 30.8906 28.3445 30.8906 27.1631C30.8906 25.9817 31.8495 25.0229 33.0309 25.0229H41.426C42.609 25.0229 43.5662 25.9817 43.5662 27.1631Z"
          fill="#CFF9FF"
        />
        <path
          d="M43.5669 27.1632C43.5669 28.3446 42.6095 29.3034 41.4267 29.3034H37.229V25.0231H41.4267C42.6096 25.0229 43.5669 25.9818 43.5669 27.1632Z"
          fill="#80DBFF"
        />
        <path
          d="M58.0929 70.8598C58.0929 72.0426 57.134 73 55.9526 73H37.229V68.7197H55.9526C57.134 68.7197 58.0929 69.6785 58.0929 70.8598Z"
          fill="#CDD2E1"
        />
        <path
          d="M42.7937 12.5286V19.8166C42.7937 20.998 41.8362 21.9569 40.6534 21.9569C39.472 21.9569 38.5132 20.998 38.5132 19.8166V12.5286C38.5132 11.3472 39.472 10.3884 40.6534 10.3884C41.8362 10.3884 42.7937 11.3472 42.7937 12.5286Z"
          fill="#80DBFF"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="73" height="73" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
}
