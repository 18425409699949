import React from "react"

export default {
  fill: "none",
  viewBox: "0 0 73 73",
  path: (
    <>
      <path
        fill="#FF873C"
        d="M56.406 40.002V67.91c0 1.78-.907 3.401-2.425 4.332a5.047 5.047 0 01-4.96.201l-5.028-2.553-7.128-3.618a.812.812 0 00-.731 0l-7.24 3.677-4.915 2.494a5.089 5.089 0 01-2.303.556c-.921 0-1.838-.254-2.658-.757a5.048 5.048 0 01-2.424-4.332V40c0-1.18.958-2.138 2.138-2.138h35.536c1.181 0 2.138.958 2.138 2.139z"
      />
      <path
        fill="#FA732D"
        d="M56.406 40.002V67.91c0 1.78-.907 3.401-2.425 4.332a5.047 5.047 0 01-4.96.201l-5.028-2.553-7.127-3.618a.754.754 0 00-.245-.077V37.863h17.647c1.182 0 2.138.958 2.138 2.139z"
      />
      <path
        fill="#FF2121"
        d="M43.993 37.863v32.028l-7.127-3.618a.81.81 0 00-.731 0l-7.24 3.676V37.863h15.098z"
      />
      <path
        fill="#CF0404"
        d="M43.993 37.863v32.028l-7.127-3.618a.756.756 0 00-.245-.077V37.863h7.372z"
      />
      <path
        fill="#D25F2D"
        d="M61.555 25.055c0 13.776-11.173 24.99-24.934 25.056h-.12c-13.816 0-25.056-11.24-25.056-25.055C11.445 11.24 22.685 0 36.501 0h.12c13.761.066 24.934 11.28 24.934 25.055z"
      />
      <path
        fill="#BF5327"
        d="M61.555 25.055c0 13.776-11.173 24.99-24.934 25.056V0c13.761.066 24.934 11.28 24.934 25.055z"
      />
      <path
        fill="#BF5327"
        d="M53.585 25.055c0 9.38-7.6 17.02-16.965 17.083-.04.002-.08.002-.12.002-9.42 0-17.085-7.665-17.085-17.085 0-9.42 7.665-17.085 17.086-17.085l.12.001c9.365.064 16.964 7.704 16.964 17.084z"
      />
      <path
        fill="#8E2E29"
        d="M53.585 25.056c0 9.38-7.6 17.018-16.964 17.082V7.971c9.365.064 16.964 7.704 16.964 17.085z"
      />
      <path
        fill="#FF9D3B"
        d="M32.394 20.86v8.39a2.14 2.14 0 01-4.276 0v-8.39a2.14 2.14 0 014.276 0zM38.83 20.861v8.389a2.14 2.14 0 01-2.138 2.138l-.07-.001a2.139 2.139 0 01-2.068-2.137v-8.389c0-1.156.92-2.1 2.067-2.137l.071-.001c1.18 0 2.139.958 2.139 2.138z"
      />
      <path
        fill="#F47E14"
        d="M45.123 20.861v8.389a2.14 2.14 0 01-4.276 0v-8.389a2.14 2.14 0 014.276 0zM38.83 20.861v8.389a2.14 2.14 0 01-2.138 2.138l-.07-.001V18.724l.07-.001c1.18 0 2.139.958 2.139 2.138z"
      />
    </>
  ),
}
