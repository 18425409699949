import React from "react"

export default {
  fill: "none",
  viewBox: "0 0 73 73",
  path: (
    <>
      <path
        d="M56.4063 40.0017V67.9111C56.4063 69.6915 55.4997 71.3123 53.9816 72.2432C52.4635 73.1755 50.609 73.2509 49.0209 72.4442L43.9933 69.8912L36.866 66.2733C36.789 66.2334 36.7049 66.2077 36.6208 66.1963C36.4568 66.1706 36.2858 66.1977 36.1347 66.2733L28.8947 69.9495L23.9796 72.4441C23.2484 72.8146 22.4615 73 21.676 73C20.7551 73 19.8386 72.7462 19.0189 72.243C17.5008 71.3121 16.5942 69.6915 16.5942 67.9109V40.0017C16.5942 38.8214 17.5522 37.8635 18.7325 37.8635H43.9933C49.7807 37.8635 54.2682 37.8635 54.2682 37.8635C55.4498 37.8635 56.4063 38.8214 56.4063 40.0017Z"
        fill="#FF873C"
      />
      <path
        d="M56.4062 40.0017V67.9111C56.4062 69.6915 55.4995 71.3123 53.9815 72.2432C52.4633 73.1755 50.6088 73.2509 49.0208 72.4442L43.9932 69.8912L36.8658 66.2733C36.7888 66.2334 36.7047 66.2077 36.6206 66.1963V37.8635H43.9932C49.7806 37.8635 54.2681 37.8635 54.2681 37.8635C55.4496 37.8635 56.4062 38.8214 56.4062 40.0017Z"
        fill="#FA732D"
      />
      <path
        d="M43.9931 37.8635V69.8911L36.8658 66.2732C36.7888 66.2332 36.7047 66.2076 36.6206 66.1962C36.4566 66.1705 36.2856 66.1976 36.1345 66.2732L28.8945 69.9494V37.8635H43.9931Z"
        fill="#FF2121"
      />
      <path
        d="M43.9932 37.8635V69.8911L36.8658 66.2732C36.7888 66.2332 36.7047 66.2076 36.6206 66.1962V37.8635H43.9932Z"
        fill="#CF0404"
      />
      <path
        d="M61.555 25.0555C61.555 38.8313 50.3821 50.0455 36.6206 50.1111H36.5009C22.6852 50.1111 11.4453 38.8712 11.4453 25.0555C11.4453 11.2399 22.6852 0 36.5009 0H36.6206C50.3821 0.0655859 61.555 11.2798 61.555 25.0555Z"
        fill="#FFC44D"
      />
      <path
        d="M61.555 25.0555C61.555 38.8313 50.3821 50.0455 36.6206 50.1111V0C50.3821 0.0655859 61.555 11.2798 61.555 25.0555Z"
        fill="#FF9D3B"
      />
      <path
        d="M53.585 25.0555C53.585 34.4352 45.9858 42.0742 36.6205 42.1384C36.5806 42.1398 36.5407 42.1398 36.5007 42.1398C27.0798 42.1398 19.415 34.4751 19.415 25.0555C19.415 15.6345 27.0798 7.96982 36.5007 7.96982C36.5407 7.96982 36.5806 7.96982 36.6205 7.97124C45.9859 8.0354 53.585 15.6745 53.585 25.0555Z"
        fill="#FF9D3B"
      />
      <path
        d="M53.5851 25.0555C53.5851 34.4352 45.9859 42.0742 36.6206 42.1384V7.97125C45.986 8.03541 53.5851 15.6745 53.5851 25.0555Z"
        fill="#F47E14"
      />
      <path
        d="M38.7584 20.8604V29.2493C38.7584 30.4296 37.8004 31.3876 36.6202 31.3876C35.4399 31.3876 34.4819 30.4296 34.4819 29.2493V20.8604C34.4819 19.6802 35.4399 18.7222 36.6202 18.7222C37.8004 18.7222 38.7584 19.68 38.7584 20.8604Z"
        fill="#FFE388"
      />
      <path
        d="M38.7589 20.8604V29.2493C38.7589 30.4296 37.8009 31.3876 36.6206 31.3876V18.7222C37.8009 18.7222 38.7589 19.68 38.7589 20.8604Z"
        fill="#FFC44D"
      />
    </>
  ),
}
