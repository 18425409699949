import React from "react"

export default {
  fill: "none",
  viewBox: "0 0 41 41",
  path: (
    <>
      <path
        d="M40.6214 20.5001C40.6214 31.7198 31.5272 40.8108 20.3107 40.8108C9.09421 40.8108 0 31.7198 0 20.5001C0 9.28681 9.09421 0.189331 20.3107 0.189331C31.5272 0.189331 40.6214 9.28681 40.6214 20.5001ZM20.8558 6.90497C16.3926 6.90497 13.5459 8.78511 11.3106 12.1266C11.021 12.5595 11.1179 13.1434 11.533 13.4581L14.3748 15.6129C14.801 15.9361 15.4084 15.8592 15.7396 15.4391C17.2026 13.5834 18.2058 12.5074 20.4326 12.5074C22.1057 12.5074 24.1752 13.5842 24.1752 15.2066C24.1752 16.4331 23.1627 17.063 21.5107 17.9891C19.5841 19.0691 17.0348 20.4133 17.0348 23.776V24.1036C17.0348 24.6463 17.4748 25.0863 18.0176 25.0863H22.6039C23.1466 25.0863 23.5866 24.6463 23.5866 24.1036V23.9944C23.5866 21.6634 30.3994 21.5664 30.3994 15.2586C30.3994 10.5083 25.472 6.90497 20.8558 6.90497ZM20.3107 27.2157C18.2334 27.2157 16.5434 28.9057 16.5434 30.983C16.5434 33.0603 18.2334 34.7503 20.3107 34.7503C22.3881 34.7503 24.078 33.0603 24.078 30.983C24.078 28.9057 22.3881 27.2157 20.3107 27.2157Z"
        fill="#1F3C88"
      />
    </>
  ),
}
