import React from "react"

export default {
  fill: "none",
  viewBox: "0 0 51 40",
  path: (
    <>
      <g clip-path="url(#clip0)">
        <path
          d="M36.1225 17.7647L27.3567 9.01878L30.8482 5.53526L36.0977 10.7729L46.5968 0.297607L50.0882 3.78114L36.1225 17.7647ZM22.4044 7.88231H0.118652V12.8235H22.4044V7.88231ZM47.1663 23.7188L43.6748 20.2353L37.2615 26.6341L30.8482 20.2353L27.3567 23.7188L33.7701 30.1176L27.3567 36.5164L30.8482 40L37.2615 33.6011L43.6748 40L47.1663 36.5164L40.7529 30.1176L47.1663 23.7188ZM22.4044 27.647H0.118652V32.5882H22.4044V27.647Z"
          fill="#1F3C88"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="50"
            height="40"
            fill="white"
            transform="translate(0.118652)"
          />
        </clipPath>
      </defs>
    </>
  ),
}
