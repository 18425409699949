import React from "react"
export default {
  viewBox: "0 0 346.41 76.21",
  path: (
    <>
      <g>
        <g>
          <path d="M63.58,18.68,37.68,75.57H25.9L0,18.68H15.41L32,57.09,48.57,18.68Z" />
          <path d="M97.71,58.46H73.18Q73.82,62.33,76,64a9.91,9.91,0,0,0,6.21,1.69,19.71,19.71,0,0,0,11.21-3.63l3.64,9.44A23.3,23.3,0,0,1,89.88,75a29,29,0,0,1-8.39,1.25,26.22,26.22,0,0,1-11.86-2.5,17.9,17.9,0,0,1-7.7-7.18,21.48,21.48,0,0,1-2.71-11,22.11,22.11,0,0,1,2.55-10.69,18.27,18.27,0,0,1,7.1-7.35A20.28,20.28,0,0,1,79.15,34.9q8.55,0,13.56,5.45t5,14.89ZM73,51.68H85.69q-.48-7.09-6.13-7.1T73,51.68Z" />
          <path d="M134.83,46.6l-7.83.81c-3.12.32-5.3,1.16-6.54,2.5a8.21,8.21,0,0,0-1.85,5.73V75.57H104.25V35.87h13.63v6.86a10.66,10.66,0,0,1,4.52-5.53,16.48,16.48,0,0,1,7.66-2.14l4-.32Z" />
          <path d="M173.47,37.4a16.78,16.78,0,0,1,6.18,7.14,24.89,24.89,0,0,1,2.21,10.78,25.64,25.64,0,0,1-2.21,10.89,17.35,17.35,0,0,1-6.22,7.38,17,17,0,0,1-16.3,1,11.62,11.62,0,0,1-5-4.44v5.41H138.05V18.68h14.36V40.55a12.38,12.38,0,0,1,5-4.11,15.77,15.77,0,0,1,7-1.54A16.72,16.72,0,0,1,173.47,37.4Zm-7.82,25.46q2-2.62,2-7.54,0-4.68-2-7.19a6.85,6.85,0,0,0-5.69-2.5,7,7,0,0,0-5.81,2.55q-2,2.53-2,7.3c0,3.22.67,5.7,2,7.42a7.61,7.61,0,0,0,11.46,0Z" />
          <path d="M220.47,39.22Q225,43.54,225,52.65V75.57H211.48V70a9.14,9.14,0,0,1-3.8,4.56,11.85,11.85,0,0,1-6.37,1.65,16.38,16.38,0,0,1-7.42-1.65,12.94,12.94,0,0,1-5.21-4.6,12,12,0,0,1-1.89-6.66A10,10,0,0,1,189,56.49c1.47-1.7,3.83-2.92,7.06-3.68a60.07,60.07,0,0,1,13.07-1.13h2.17v-1c0-2-.45-3.38-1.37-4.16s-2.53-1.17-4.84-1.17a22,22,0,0,0-6.33,1.09A36.68,36.68,0,0,0,192,49.26l-3.63-9.44a34.56,34.56,0,0,1,8.67-3.51,36.81,36.81,0,0,1,9.56-1.41Q216,34.9,220.47,39.22Zm-11,25.33a7.56,7.56,0,0,0,1.85-5.28v-1H210q-5.16,0-7.34.93a3.19,3.19,0,0,0-2.18,3.11,4.23,4.23,0,0,0,1.17,3.06,4,4,0,0,0,3,1.21A6.11,6.11,0,0,0,209.46,64.55Z" />
          <path d="M238.42,75a24.09,24.09,0,0,1-8.15-3.55l3.64-9.76A28.39,28.39,0,0,0,241.25,65a27.28,27.28,0,0,0,7.91,1.25,7.84,7.84,0,0,0,3.63-.69,2.09,2.09,0,0,0,1.29-1.89,1.8,1.8,0,0,0-.93-1.62,14.92,14.92,0,0,0-3.75-1.21L243,59.43q-6-1.36-8.71-4c-1.78-1.78-2.66-4.31-2.66-7.59a10.77,10.77,0,0,1,2.26-6.74,14.36,14.36,0,0,1,6.33-4.55,24.87,24.87,0,0,1,9.24-1.62,30.44,30.44,0,0,1,16.22,4.76L262,49a30.21,30.21,0,0,0-6.49-3.1,19.46,19.46,0,0,0-6.17-1.09,7.53,7.53,0,0,0-3.63.72,2.29,2.29,0,0,0-1.3,2.1A1.54,1.54,0,0,0,245.2,49,9.72,9.72,0,0,0,248,50l6.69,1.53q6.3,1.38,9.12,4.24a10.63,10.63,0,0,1,2.82,7.79,10.79,10.79,0,0,1-4.72,9.27q-4.71,3.39-12.95,3.39A43.39,43.39,0,0,1,238.42,75Z" />
          <path d="M304.55,39.22Q309,43.54,309,52.65V75.57H295.55V70a9.12,9.12,0,0,1-3.79,4.56,11.88,11.88,0,0,1-6.38,1.65A16.38,16.38,0,0,1,278,74.56a12.85,12.85,0,0,1-5.2-4.6,12,12,0,0,1-1.9-6.66,10,10,0,0,1,2.22-6.81c1.48-1.7,3.83-2.92,7.06-3.68a60.14,60.14,0,0,1,13.07-1.13h2.18v-1c0-2-.46-3.38-1.37-4.16s-2.53-1.17-4.85-1.17a22.06,22.06,0,0,0-6.33,1.09,37,37,0,0,0-6.74,2.78l-3.63-9.44a34.65,34.65,0,0,1,8.68-3.51,36.75,36.75,0,0,1,9.56-1.41Q300.07,34.9,304.55,39.22Zm-11,25.33a7.57,7.57,0,0,0,1.86-5.28v-1H294.1c-3.45,0-5.89.31-7.35.93a3.18,3.18,0,0,0-2.17,3.11,4.23,4.23,0,0,0,1.17,3.06,4,4,0,0,0,3,1.21A6.09,6.09,0,0,0,293.53,64.55Z" />
          <polygon
            fill="#1f3c88"
            points="321.8 19.97 321.8 0 301.83 0 301.83 9.75 312.05 9.75 312.05 19.97 321.8 19.97"
          />
          <polygon
            fill="#1f3c88"
            points="346.41 19.97 346.41 0 326.44 0 326.44 9.75 336.66 9.75 336.66 19.97 346.41 19.97"
          />
          <polygon
            fill="#1f3c88"
            points="346.41 44.59 346.41 24.61 326.44 24.61 326.44 34.36 336.66 34.36 336.66 44.59 346.41 44.59"
          />
        </g>
      </g>
    </>
  ),
}
