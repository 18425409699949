import React from "react"

export default {
  fill: "none",
  viewBox: "0 0 54 54",
  path: (
    <>
      <path
        d="M39.9877 35.0844C39.9707 35.0889 39.9537 35.094 39.9371 35.0993L29.7478 38.3858C29.485 36.9571 28.2375 35.567 26.1484 35.4825L23.7811 35.3867C23.2004 35.3609 22.7082 35.8159 22.6847 36.3979C22.6611 36.98 23.1138 37.4708 23.6959 37.4943L26.0632 37.5901C27.1727 37.635 27.6845 38.3226 27.6907 38.9424C27.6923 39.0979 27.6642 39.2409 27.6088 39.37C27.5911 39.4013 27.5751 39.4336 27.5607 39.4665C27.3395 39.8547 26.8453 40.0968 26.147 40.1389L18.3556 40.6098C17.7742 40.6449 17.3314 41.1447 17.3665 41.7261C17.4015 42.3076 17.8983 42.75 18.4829 42.7153L26.2743 42.2445C27.6932 42.1588 28.6582 41.557 29.2149 40.7742L40.559 37.1152C41.7316 36.8041 43.7488 36.5025 44.2889 37.8952C44.335 38.0314 44.2349 38.1623 43.9916 38.2842L29.9067 45.0557C27.5183 45.919 22.2301 46.273 17.9756 46.558C16.0918 46.6841 14.3126 46.8033 12.967 46.9522C12.869 46.9631 12.7732 46.9875 12.682 47.025L2.10937 51.3713V42.508H6.39718C6.97958 42.508 7.45186 42.0358 7.45186 41.4533C7.45186 40.2276 7.96191 39.035 8.85122 38.1809C9.68073 37.3843 10.7421 36.9704 11.8453 37.0147L14.2119 37.1104C14.7924 37.1359 15.2848 36.6813 15.3083 36.0993C15.3318 35.5173 14.8792 35.0264 14.2971 35.0028L11.9305 34.9071C10.2496 34.8383 8.63765 35.4614 7.39006 36.6595C6.34571 37.6625 5.65869 38.9862 5.42814 40.3986H1.05468C0.472287 40.3986 0 40.8708 0 41.4533V52.9452C0 53.297 0.175394 53.6256 0.467647 53.8215C0.643884 53.9396 0.848598 53.9999 1.05479 53.9999C1.19042 53.9999 1.32658 53.9738 1.45578 53.9207L13.3453 49.0329C14.6293 48.8961 16.3249 48.7825 18.1161 48.6626C22.763 48.3514 28.0298 47.9988 30.6826 47.0177C30.7136 47.0062 30.744 46.9933 30.7738 46.979L44.9121 40.1819C44.9161 40.1799 44.9202 40.178 44.9242 40.176C46.3711 39.4574 46.6286 38.0956 46.2558 37.1344C45.4232 34.9871 43.1386 34.2398 39.9877 35.0844V35.0844Z"
        fill="#1F3C88"
      />
      <path
        d="M53.5326 0.178557C53.2406 -0.0170868 52.8701 -0.0544226 52.5445 0.0793113L40.6548 4.96714C39.3708 5.10393 37.6752 5.21752 35.8841 5.33744C31.2371 5.64857 25.9703 6.00125 23.3175 6.98232C23.2866 6.99382 23.2561 7.00668 23.2264 7.02103L9.08792 13.818C9.08391 13.82 9.07991 13.8219 9.0759 13.8239C7.62898 14.5424 7.37143 15.9043 7.74426 16.8654C8.35239 18.4339 9.73497 19.2554 11.6639 19.2554C12.3761 19.2554 13.1628 19.1433 14.0124 18.9156C14.0294 18.9111 14.0464 18.906 14.0631 18.9007L24.2524 15.6142C24.5152 17.0429 25.7627 18.433 27.8517 18.5175L42.0697 19.0929C43.75 19.1616 45.3625 18.5385 46.6101 17.3405C47.6544 16.3375 48.3414 15.0138 48.572 13.6014H52.9456C53.528 13.6014 54.0002 13.1292 54.0002 12.5467V1.05479C54.0001 0.703051 53.8247 0.374412 53.5326 0.178557V0.178557ZM51.8908 11.492H47.603C47.0206 11.492 46.5483 11.9642 46.5483 12.5467C46.5483 13.7724 46.0382 14.965 45.1489 15.8191C44.3194 16.6157 43.2577 17.0296 42.1549 16.9854L27.9369 16.4099C26.8275 16.365 26.3156 15.6774 26.3094 15.0576C26.3078 14.9025 26.3359 14.7596 26.3912 14.6306C26.409 14.5989 26.4252 14.5663 26.4398 14.5329C26.6612 14.145 27.1554 13.9032 27.8533 13.861L35.6446 13.3902C36.2261 13.355 36.6689 12.8552 36.6337 12.2738C36.5987 11.6923 36.099 11.2495 35.5173 11.2846L27.7261 11.7554C26.307 11.8411 25.3421 12.4429 24.7853 13.2257L13.4411 16.8847C12.2686 17.1958 10.2514 17.4973 9.71135 16.1047C9.66515 15.9685 9.76524 15.8377 10.0086 15.7157L24.0935 8.94424C26.482 8.08088 31.7702 7.72693 36.0246 7.44195C37.9084 7.31581 39.6877 7.19663 41.0332 7.04771C41.1312 7.03685 41.2271 7.01238 41.3182 6.97494L51.8905 2.62869V11.492H51.8908Z"
        fill="#1F3C88"
      />
      <path
        d="M26.7278 34.0144C26.817 34.0382 26.9086 34.0501 27 34.0501C27.0914 34.0501 27.183 34.0382 27.2721 34.0145C29.3383 33.4625 33.8642 29.7896 34.8759 26.0083C35.3663 24.1749 34.9618 22.544 33.706 21.2922C31.9087 19.5007 28.9842 19.5007 27.1869 21.2922C27.0089 21.4696 26.8442 21.3233 26.813 21.2922C25.0158 19.5005 22.0912 19.5007 20.294 21.2922C19.0382 22.5441 18.6337 24.1749 19.1241 26.0083C20.1358 29.7895 24.6618 33.4624 26.7278 34.0144V34.0144ZM21.7832 22.786C22.2714 22.2994 22.9123 22.0562 23.5536 22.0562C24.1946 22.0562 24.8359 22.2995 25.3239 22.7859C26.2638 23.7228 27.7363 23.7228 28.676 22.7859C29.6523 21.8129 31.2405 21.8128 32.2168 22.7859C32.9371 23.504 33.1346 24.3547 32.8381 25.4629C32.0883 28.2658 28.5543 31.2326 27 31.8811C25.4457 31.2326 21.9118 28.2658 21.1619 25.4629C20.8654 24.3548 21.0628 23.5041 21.7832 22.786V22.786Z"
        fill="#1F3C88"
      />
      <path
        d="M17.9419 36.2487C17.9419 36.8312 18.4144 37.3033 18.9969 37.3033C19.5794 37.3033 20.0516 36.8312 20.0516 36.2487C20.0516 35.6662 19.5793 35.194 18.9969 35.194H18.9962C18.4137 35.194 17.9419 35.6662 17.9419 36.2487Z"
        fill="#1F3C88"
      />
    </>
  ),
}
